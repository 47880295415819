.table {
  table tr td {
    white-space: nowrap;
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;
    p {
      white-space: normal;
    }
  }
}
.icon {
  font-size: 20px;
  color: #00989B;
  margin-right: 5px;
}
.iconDelete {
  color: #ff455e;
}

.phoneNumber {
    width: 100% !important;
    height: 35px !important;
}

.content {
    margin-top: 30px !important;
    margin-bottom: 15px !important;
}

.header_content {
    margin-bottom: 10px;
}

.logo {
    margin-right: 20px;
    width: 120px !important;
}

.header_form {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {
   .logo{
     margin-right: 5px;
   }

   .logo img{
        width: 80px;
   }

   h3{
        font-size: 18px;
   }
}
    
.createButton {
  margin-top: 20px;
  width: 100%;
}

.stepButton {
  width: min-content !important;
  display: flex !important;
  margin-left: auto;
  white-space: nowrap !important;

  @media (max-width: 560px) {
    width: 100% !important;
  }
}

.sendButton {
  position: absolute !important;
  right: 0;
  top: 15px;
}

.chatCard {
  border: 1px solid #c0c0c0a3;
  border-radius: 10px;
  margin-bottom: 20px;

  &.pinned {
    background-color: #ffefc2;
    border-color: #ffb100;
  }

  &.private {
    background-color: #f0f3f5;
  }
}

.heading {
  padding: 10px;
  font-weight: bold;
  border-bottom: 1px solid #c0c0c0a3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin-bottom: 0;
  }
}

.cardDescription {
  padding: 10px;
}

.date {
  color: #4d4d4d69;
  text-align: right;
  display: block;
  font-size: 12px;
}

.chatWrapper {
  margin-top: 30px;
}

.loadMore {
  display: block !important;
  margin: 0 auto !important;
}

.editorBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.cardBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.privateTag {
  font-size: 10px;
  color: #4d4d4d;
  font-style: italic;
}

.checkbox {
  margin-top: 20px !important;
}

.table {
  table tr td {
    white-space: nowrap;
    max-width: 380px;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      white-space: normal;
    }
  }
}

.icon {
  font-size: 20px;
  color: #00989B;
  margin-right: 5px;
}

.iconDelete {
  color: #ff455e;
}

.pinIcon {
  vertical-align: middle;
}


.readInfo {
  color: #4d4d4d69;
  text-align: left;
  font-size: 12px;
  margin-top: 22px;
  margin-bottom: 0;
  display: block;
  @media (max-width: 560px) {
    text-align: center;
  }
}

/* For messages not yet read */
.messageUnread {
  border-left: 4px solid #00999B; /* Add a colored highlight, e.g., a blue left border */
  padding-left: 10px; /* Add left padding so that text is not too close to the left border */
}

/* For messages that are read */
.messageRead {
  // opacity: 0.6; /* Make the message more opaque */
}

.noUpload {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 80px;
}

// styled.module.scss

.advantage-club-card {
    margin-bottom: 16px;
    border-radius: 8px;
    
    .card-image {
      width: 100%;
      height: 350px;
      border-radius: 8px;
      object-fit: cover; // Isso irá garantir que a imagem cubra todo o contêiner mantendo a proporção.
    }
  
    .card-content {
      margin-top: 12px;
    }
  
    .details-link {
      margin-top: 12px;
      display: block;
    }
  }

  .view-details-link{
    margin-top: 10px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
  }

  .banner-advantage-club{
 
    margin-bottom: 10px;
  }

  .image-modal{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover; // Isso irá garantir que a imagem cubra todo o contêiner mantendo a proporção.
  }
.hp-full-background {
  background-image: url("../../assets/images/bg-bna-v2.png");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100vh; /* Ocupa toda a altura da visualização */
}

/* Ocultar a coluna inteira em dispositivos móveis */
@media screen and (max-width: 576px) {
  .hp-left-content {
    display: none !important; /* Oculta toda a coluna em dispositivos móveis */
  }
}

/* Estilos para a página de autenticação */
.hp-authentication-page {
  height: 100vh; /* Ocupa a tela toda */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Centraliza o formulário no centro vertical em telas menores */
.hp-auth-form-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  height: 100%; /* Garante que o formulário ocupe toda a altura disponível */
}

.hp-auth-col {
  background-color: #fff;
}

/* Para telas pequenas, remove o padding e ajusta o layout */
@media (max-width: 576px) {
  .hp-auth-form-wrapper {
    padding: 0 15px;
  }

  .hp-auth-form {
    margin-top: 0;
    padding-bottom: 20px;
  }

  .hp-auth-col {
    background-color: #fff;
  }
}

.card {
    flex: 1;
    margin-right: 4px !important;
    margin-left: 4px !important;
    margin-bottom: 15px !important;
  }

  .bg-blue{
    background-color: #00999b44 !important;
  }

  .bg-red{
    background-color: rgba(192, 57, 43, 0.2) !important;
  }

  .bg-red-with-hover{
    background-color: rgba(192, 57, 43, 0.2) !important;
  }

  .bg-red-with-hover:hover{
    background-color: rgba(192, 57, 43, 0.4) !important;
  }

  .card-title{
    padding-bottom: 4px;
    border-bottom: 1px solid #33333333;
    font-size: 16px;
  }

  .statistic{
    font-size: 32px;
  }
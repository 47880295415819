.buttonWrapper {
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
}
.phoneNumber {
  width: 100% !important;
  height: 33px !important;
  &:disabled {
    color: #b2bec3;
    background-color: #f0f3f5;
    border-color: #b2bec3;
  }
}

.icon {
  font-size: 20px;
  color: #00999B;
  margin-right: 5px;
}

.iconDelete {
  color: #ff455e;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
